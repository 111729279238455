import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { fetchFile } from '@ffmpeg/ffmpeg';
import getVideoFrames from "zivax"
import decodeVideo from "../../libraries/decoder.js"
import videoExtract from "../../libraries/video-extracter.js"

let canvasEl;
let ctx;

const FRAME_CUT = 30;  //30 means capture 1 in every 30 frames (29 frames are discarded).
const CANVAS_RATIO =  2; //1 means orginal resolution images, 2 means half resolution images etc..
const JPEG_COMPRESSION = 0.95 //0.95 means best image quality compression
const SEEK_JUMP_TIME = 0.50 //HOW FAST NEED TO SEEK THE VIDEO

export default function UploadButtons() {

//**********************************************************************//
const onSelectVideoFile = (e) => {
  /*
  console.log('CALLING WORKR ...');
  videoExtract(e.target.files[0], (message)=>{
    console.log('RESPONSE => ',message.data)
  });
  return;
  */

  //console.log(e.target.files[0]);

  /*
  let vidUrl = URL.createObjectURL(e.target.files[0]);
  decodeFramesWithPlayingSpeedOnly(id, vidUrl);
  getVideoFramesUsingWebCodecs(vidUrl);
  return;
  */

  let {name, size, type} = e.target.files[0];
  let id = name.toLowerCase()+'_' + new Date().valueOf();
  //console.log('SOURCE', name, size, type);

  let url = URL.createObjectURL(e.target.files[0]); //CURRENTLY NO ONE USE THIS URL, SHOULD BE DESTROYED

  window.medias.set(id, {name, url, size, type, frame1:''});
  window.mediaScenes.set(id, {name, start:0, end:-1, duaration:-1, layer: (window.mediaScenes.size+1) });
  window.broadcast('EVENT_MEDIA_FILE_SELECTED', {id, name, size, type, url});
  

  //This method will use ffmpeg (wasm) to extract the frames from video
  //But relativily slower than loadVideoDurationAndFrames() and loadFramesUsingMP4Demuxer()
  //Before activatating this method, please enable initializeFFMPEG() in initApp.js
  //addMediaToFFMPEG(id, name, url);  //!DO NOT DELETE THIS LINE

  //This method is faster on getting durarion and first frame 
  //but slower than loadFramesUsingMP4Demuxer() when loading all frames, 
  //used to capture video duration and first frame.
  //when first parameter is true (capture all frames but slower) then please comment loadFramesUsingMP4Demuxer()
  loadVideoDurationAndFrames(true, {id, name, file:e.target.files[0] });
  
  //This function loads the entire video frames faster than loadVideoDurationAndFrames() method 
  //but somehow missing many last frames (investigating, please see the copy of mod.js in public directory)..
  //Currently used to capture just all frames (because faster) not for duration and first frame..
  //Similar code is developed in demuxer-worker.js (decodeVideo('http://video.mp4')) but same frame drop issue.
  //[12Mar2023] => now this will get most frames quickly but missing many frames..
  loadFramesUsingMP4Demuxer (id, name, e.target.files[0]);

  //This method get all frames, but sometimes frame generation is slow
  //check memory/cpu is building up during the process..
  //till this issue fixes, will continue use loadFramesUsingMP4Demuxer() ..
  //[12Mar2023] => now this will get all frames what ever missed from loadFramesUsingMP4Demuxer() 
  //[12Mar2023] => and since using worker thread no main thread issue, but take some time..
  //getVideoFramesUsingWebCodecs(id, name, e.target.files[0]);

}
//**********************************************************************//

//**********************************************************************//
const decodeFramesWithPlayingSpeedOnly = async (id, startTime=0.00) => {
  let stopped = false;
  const track = await getVideoTrack();
  const processor = new window.MediaStreamTrackProcessor(track);
  const reader = processor.readable.getReader();
  readChunk();

  const imgPlayer = document.getElementById(`img-player-${id}`);
  const playerBounds = imgPlayer.getBoundingClientRect();
  imgPlayer.parentNode.style.display = 'none';
  
  const showCanvas = true;
  const canvasElem = document.createElement('canvas');
  canvasElem.width = 640;
  canvasElem.height = 360;
  if(showCanvas){
      canvasElem.style.display = '';
      canvasElem.style.position = 'absolute';
      canvasElem.style.zIndex = 1000;
      canvasElem.style.width = playerBounds.width +'px';
      canvasElem.style.height = playerBounds.height +'px';
      canvasElem.style.top = playerBounds.top +'px';
      canvasElem.style.left = playerBounds.left + 'px';
  }
  else{
      canvasElem.style.display = 'none';
  }
  document.body.appendChild(canvasElem);
  let context = canvasElem.getContext("2d"); 

  function readChunk() {
    reader.read().then(async({ done, value }) => {
      if (value) {
        //console.log(value);
        context.drawImage(value, 0, 0, canvasElem.width, canvasElem.height);
        /*
        //console.log(canvasElem.toDataURL("image/png"));
        canvasElem.toBlob(
          (blob) => {
            let videoUrl = URL.createObjectURL(blob);
            console.log('PLAYING .. => ', videoUrl);
            imgPlayer.src = videoUrl;
          },
          "image/jpeg",
          JPEG_COMPRESSION
        );
        */
        value.close();
      }
      if (!done && !stopped) {
        readChunk();
      } else {
        console.log('READY  !!')
      }
    });
  }

  async function getVideoTrack() {
    let videoElement = document.getElementById(`video-x-${id}`);
    var video = videoElement ? videoElement : document.createElement('video');
    video.preload = 'auto';
    //video.playbackRate = 1.25;
    video.style.display = 'none';
    //video.crossOrigin = "anonymous";
    //video.controls = true;
    video.controls = "muted";
    console.log('PLAY USRL ...', window.medias.get(id).url);
    video.src = window.medias.get(id).url;

    if(!videoElement){
      video.id = `video-x-${id}`;

      if(false){
        video.style.display = '';
        video.style.position = 'absolute';
        video.style.top = '320px';
        video.style.width = '300px';
        video.style.height = '210px';
      }

      document.body.appendChild(video);
    }

    //console.log(video.currentTime, startTime);
    video.currentTime = startTime;
    //console.log(video.currentTime, startTime, video.src);
    await video.play();
    const [track] = video.captureStream().getVideoTracks();
    video.onended = (evt) => {
      imgPlayer.parentNode.style.display = '';
      canvasElem.style.display = 'none';
      track.stop();
    }
    return track;
  }
}; //end of decodeFramesWithPlayingSpeedOnly()
//**********************************************************************//

//**********************************************************************//
const loadVideoDurationAndFrames = (captureAllFrames=false, {id, name, file}) => {

  const startTime = performance.now();
  let videoElement = document.getElementById(`video-${id}`);
  var video = videoElement ? videoElement : document.createElement('video');
  video.style.display = 'none';
  video.preload = 'metadata';

  let startVideo = 0.00;
  let firstFrameCaptured = false;
  let frameImages = [];

  video.onloadedmetadata = function() {
    console.log('PERFORMANCE', 'onloadedmetadata', performance.now()-startTime);
    //window.URL.revokeObjectURL(video.src);
    let duration = video.duration;  //MULTIPLYING WITH 10 (need to check!!)
    console.log('video.duration:', video.duration);
    
    //Set duration and end time of the media ..
    let mediaSceneObject = window.mediaScenes.get(id);
    mediaSceneObject.duration = duration;
    mediaSceneObject.end = duration;
    window.mediaScenes.set(id, mediaSceneObject);
    
    window.broadcast('EVENT_MEDIA_DURATION_RECIEVED', {id, name, duration});
  }

  video.onloadeddata = function(e) {
    console.log('PERFORMANCE', 'onloadeddata', performance.now()-startTime);
    video.currentTime = startVideo;
    console.log('onloadeddata', e);
  }

  video.onseeked = function (e) {
    console.log('PERFORMANCE', 'onseeked', performance.now()-startTime);
    //console.log('onseeked', e, video.currentTime);
    
    const canvas = document.createElement('canvas')
    const width = canvas.width = video.videoWidth
    const height = canvas.height = video.videoHeight
    const context = canvas.getContext('2d')
    context.drawImage(video, 0, 0, width, height);
    
    canvas.toBlob(
      (blob) => {
        let videoUrl = URL.createObjectURL(blob);
        //console.log('onseeked videoUrl: ', videoUrl);
        frameImages.push(videoUrl);

        if(!firstFrameCaptured){
          let mediaFrameObject = window.medias.get(id);
          mediaFrameObject.frame1 = videoUrl;
          window.medias.set(id, mediaFrameObject);
          window.broadcast('EVENT_MEDIA_FIRST_FRAME_OBTAINED', {id, name, frameImage: videoUrl});
          firstFrameCaptured = true;
        }
        console.log(frameImages.length);
        if(frameImages.length === 99999910){
          console.log('GOT 10 frames, tell what we have got ...');
          window.mediaFrames.set(id, {name, frameImages});
          window.broadcast('EVENT_MEDIA_10_FRAMES_OBTAINED', {id, name});
        }
        if(frameImages.length === 999999920){
          console.log('GOT 20 frames, tell what we have got ...');
          window.mediaFrames.set(id, {name, frameImages});
          window.broadcast('EVENT_MEDIA_20_FRAMES_OBTAINED', {id, name});
        }

      },
      "image/jpeg",
      JPEG_COMPRESSION
    );

    if(captureAllFrames){
      if(startVideo <= video.duration){
        startVideo = startVideo + SEEK_JUMP_TIME;
        video.currentTime = startVideo;
        console.log('Setting current video time to', video.currentTime);
      }
      else{
        window.mediaFrames.set(id, {name, frameImages});
        window.broadcast('EVENT_MEDIA_ALL_FRAMES_OBTAINED', {id, name});
        window.URL.revokeObjectURL(video.src);
      }
    }
    else{
      window.URL.revokeObjectURL(video.src);
    }

  };
  
  video.src = URL.createObjectURL(file);
  if(!videoElement){
    video.id = `video-${id}`;

    if(false){
      video.style.display = '';
      video.style.position = 'absolute';
      video.style.top = '0px';
      video.style.width = '300px';
      video.style.height = '210px';
    }

    document.body.appendChild(video);
  }
}
//**********************************************************************//

//**********************************************************************//
//Do not delete this method
const getVideoFramesUsingWebCodecs = async (id, name, fileStream) =>{

  let blob = new Blob([fileStream]);
  let videoUrl = URL.createObjectURL(blob);

  let imgCount = 0;
  let frameImages = await new Promise(resolve => {
    decodeVideo(videoUrl, (frameImages)=> {
      //console.log(frameImages);
      resolve(frameImages);
    });
  });

  /*
  await new Promise(resolve => {
    let timerHandle;
    decodeVideo(videoUrl, (imgSrc) => {
      frameImages.push(imgSrc);
      imgCount++;
      if(timerHandle){
        clearTimeout(timerHandle);
      }
      timerHandle = setTimeout(resolve, 100);
      console.log('imgCount', imgCount);
    });
  });
  */

  URL.revokeObjectURL(videoUrl);

  //storing all the rendered frames for editing and plating from timeline..
  window.mediaFrames.set(id, {name, frameImages});

  //Telling every component all frames are ready
  window.broadcast('EVENT_MEDIA_ALL_FRAMES_OBTAINED', {id, name});
  
}
//**********************************************************************//

//**********************************************************************//
const loadFramesUsingMP4Demuxer = async(id, name, fileStream) => {
  let blob = new Blob([fileStream]);
  let videoUrl = URL.createObjectURL(blob);
  
  console.log(videoUrl);
  let k = 0;
  let duration = -1;
  let codedWidth = -1;
  let codedHeight = -1;
  let frameImages = [];

  await getVideoFrames({
    videoUrl,
    onFrame(frame) {  // `frame` is a VideoFrame object: https://developer.mozilla.org/en-US/docs/Web/API/VideoFrame
      
      if(++k % FRAME_CUT === 0){
        
        /*
        if(duration == -1 && frame.duration){ //BETTER SOLUTION video.duration ..
          console.log(frame);
          duration = frame.duration

          //Set duration and end time of the media ..
          let mediaSceneObject = window.mediaScenes.get(id);
          mediaSceneObject.duration = duration;
          mediaSceneObject.end = parseInt(duration/100);
          window.mediaScenes.set(id, mediaSceneObject);

          window.broadcast('EVENT_MEDIA_DURATION_RECIEVED', {id, name, duration});
        }
        */

        ctx.drawImage(frame, 0, 0, canvasEl.width, canvasEl.height);
        //console.log(canvasEl.toDataURL("image/png"));
        canvasEl.toBlob(
          (blob) => {
            let videoUrl = URL.createObjectURL(blob);
            /*
            if(frameImages.length === 0){ // BETTER SOLUTION video.onseeked .. 

              let mediaFrameObject = window.medias.get(id);
              mediaFrameObject.frame1 = videoUrl;
              window.medias.set(id, mediaFrameObject);

              window.broadcast('EVENT_MEDIA_FIRST_FRAME_OBTAINED', {id, name, frameImage: videoUrl});
            }
            */
            frameImages.push(videoUrl);

            console.log(frameImages.length);
            if(frameImages.length === 10){
              console.log('GOT 10 frames, tell what we have got ...');
              window.mediaFrames.set(id, {name, frameImages});
              window.broadcast('EVENT_MEDIA_10_FRAMES_OBTAINED', {id, name});
            }
            else if(frameImages.length === 20){
              console.log('GOT 20 frames, tell what we have got ...');
              window.mediaFrames.set(id, {name, frameImages});
              window.broadcast('EVENT_MEDIA_20_FRAMES_OBTAINED', {id, name});
            }
          },
          "image/jpeg",
          JPEG_COMPRESSION
        );
        //let frameBlob = new Blob([imgData.data]);
        //let frameUrl = URL.createObjectURL(frameBlob);
        //console.log(frameUrl);
      }

      frame.close();
    },
    onConfig(config) {
      console.log(config);
      codedWidth = config.codedWidth;
      codedHeight = config.codedHeight;
      canvasEl.width = config.codedWidth/CANVAS_RATIO;
      canvasEl.height = config.codedHeight/CANVAS_RATIO;
    },
  });
  URL.revokeObjectURL(blob);

  //storing all the rendered frames for editing and plating from timeline..

  window.mediaFrames.set(id, {name, frameImages});
  
  //Telling every component all frames are ready
  window.broadcast('EVENT_MEDIA_ALL_FRAMES_OBTAINED', {id, name});
}

//DO NOT DELETE THIS FUNCTION
const addMediaToFFMPEG = async (id, name, url) => {
  if(window.ffmpeg){  
     window.ffmpeg.FS('writeFile', name, await fetchFile(url));
     console.log(`video ${name} added to ffmpeg memory file system.`);  
     window.broadcast('EVENT_FFMPEG_MEDIA_WRITTEN', {name, url});
  }
  else{
    console.log('WARNING - addMediaToFFMPEG(), ffmpeg not available!');
  }
}
//**********************************************************************//

useEffect(() => {
  canvasEl = document.getElementById("canvasEl");
  ctx = canvasEl.getContext("2d"); 
  
  setTimeout(()=>{
    console.log('PLAYING ....')
    return;
    
    let i = 0.00;
    for(let [key, val] of window.medias) {
      i = i + 10;
      decodeFramesWithPlayingSpeedOnly(key, i);
    }

  }, 8000)
  

});


return (
  <>
  <canvas hidden id="canvasEl"></canvas>
  
  <Stack className="browse-file" direction="row" alignItems="center" spacing={2}>
      <Button variant="contained" component="label">
      Add Media
      <input onChange={onSelectVideoFile} hidden accept="video/*" multiple type="file" />
    </Button>
    
  </Stack>
  </>
);
}