import React, { useState, useEffect }  from 'react';
import logo from './logo.svg';
import Slider from './components/Slider';
import Progress from './components/Progress';

import SplitPane, { Pane } from 'react-split-pane';

import ProjectTabs from './components/ProjectTabs/ProjectTabs';
import ControlTabs from './components/ControlTabs/ControlTabs';
import BrowseFile from './components/Bins/BrowseFile';
import TimelineTabs from './components/TimelineTabs/TimelineTabs';

import axios from 'axios';
import { TSDemux, FLVDemux, MP4Demux, Events } from 'demuxer';

import './App.css';

const chunks = [
  {
    offset: 0,
    size: 4045
  },
  {
    offset: 4045,
    size: 926
  },
  {
    offset: 4971,
    size: 241
  },
  {
    offset: 5212,
    size: 97
  },
  {
    offset: 5309,
    size: 98
  },
  {
    offset: 5407,
    size: 624
  },
  {
    offset: 6031,
    size: 185
  },
  {
    offset: 6216,
    size: 94
  },
  {
    offset: 6310,
    size: 109
  },
  {
    offset: 6419,
    size: 281
  }
];

function App() {

  const processVideo = async (videoUrl) => {
    let response = await axios(videoUrl, { responseType: 'arraybuffer' });
    let videoBytes = response.data;

    // /const url = window.URL.createObjectURL(new Blob([videoBytes]));
    console.log(videoBytes);

    let outputs = 0;
    let decoder = new window.VideoDecoder({
      error: (err) => console.log("Unexpected error", err),
      output: (frame) => { 
        console.log('outputs:', ++outputs); 
        console.log(frame);
      },
    });

    const config = {
      codec: "avc1.64001f",
      codedWidth: 1280,
      codedHeight: 720,
      description: new Uint8Array([1, 100, 0, 31, 255, 225, 0, 28, 103, 100, 0, 31, 172, 209, 0, 80, 5, 187, 1, 106, 2, 2, 2, 128, 0, 0, 3, 0, 128, 0, 0, 25, 7, 140, 24, 137, 1, 0, 4, 104, 235, 143, 44])
    };
    // 0, 0, 0, 51, 97, 118, 99, 67, 

    decoder.configure(config);
    let counter = 0;
    for (let chunk of chunks) {
      let buffer = new Uint8Array(videoBytes, chunk.offset, chunk.size);
      //console.log(buffer);
      let encoded_chunk = new window.EncodedVideoChunk({
        type: (counter == 0) ? 'key' : 'delta',
        timestamp: counter * 1_00_000,
        data: buffer
      });
      counter++;
      decoder.decode(encoded_chunk);
    };
    await decoder.flush();
    decoder.close();
    
  }


  useEffect(() => {

    return;  
    
    //let videoUrl = `https://w3c.github.io/webcodecs/samples/data/bbb_video_avc_frag.mp4`;
    let videoUrl = `https://bobbyt.s3.amazonaws.com/Sleep.mp4`;
    processVideo(videoUrl).then(data=>{
      console.log('Video processed successfully.');
    });

   
    
    fetch(videoUrl).then((res) => {
      res.arrayBuffer().then((bytes) => {
          
        console.log(bytes);


          let outputs = 0;
          let decoder = new window.VideoDecoder({
            error: () => console.log("Unexpected error"),
            output: (frame) => { console.log('outputs:', ++outputs); },
          });

          const config = {
            codec: "avc1.42001E",
            codedWidth: 320,
            codedHeight: 240
            /* no description means Annex B */
          };

          decoder.configure(config);
          let counter = 0;
          for (let chunk of chunks) {
            let buffer = new Uint8Array(bytes, chunk.offset, chunk.size);
            let encoded_chunk = new window.EncodedVideoChunk({
              type: (counter == 0) ? 'key' : 'delta',
              timestamp: counter * 1_00_000,
              data: buffer
            });
            counter++;
            decoder.decode(encoded_chunk);
          };
          decoder.flush();
          decoder.close();
      
          let demux = new MP4Demux({
              debug: true // if env is production, debug should be false or not specified.
          });

          demux.on(Events.DEMUX_DATA, (e) => {
              console.log('DEMUX_DATA')
              console.log(e);
              if (e.stream_type === 15) {
                console.log(e)
              }
          });

          demux.on(Events.DONE, (e) => {
              console.log('consumed & flushed all pipe buffer.');
          });

          // buffer -> video bytes ArrayBuffer 
          demux.push(bytes, {
            // Support push part of the data for parsing
            // When done is set to true, if the data is decoded and there is no remaining data, the data is considered to have been pushed and Events.DONE will be emitted.
            // When done is set to false, Events.DONE will not be emit, waiting for subsequent data push
            done: true
          });
      });
   });


    window.listen('EVENT_MEDIA_FILE_UPLOADED', (newValue, oldValue)=> {
       console.log('GOT', newValue);
    });

  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <SplitPane split="vertical" defaultSize={300}>
          <SplitPane split="horizontal" defaultSize={450}>
            <ProjectTabs />
            <BrowseFile />
          </SplitPane>
          <SplitPane split="horizontal" defaultSize={450}>
            <SplitPane split="vertical" defaultSize={900}>
              <ControlTabs />
              <div style={{width:'100%', height:'100%'}}><span class="material-symbols-rounded">settings</span></div>
            </SplitPane>
            <SplitPane split="vertical" defaultSize={900}>
              <TimelineTabs />
              <div style={{width:'100%', height:'100%'}}><span class="material-symbols-rounded">settings</span></div>
            </SplitPane>
          </SplitPane>
        </SplitPane>
      </header>
    </div>
  );
}

export default App;
