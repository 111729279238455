import React, { useState, useEffect }  from 'react';
import TimelineElement from "./element.js";
import TimelineSlider from  '../TimelineSlider'
//import { setGlobalState } from '../../libraries/global-state';

import './timeline-grid.css';

let debounceHandle = null;
let appInitialized = false;
let playTimerHandle;
//let playTime = 0.01;

export default function TimelineGrid({maxDuration, elements}) {
    //alert(elements[0].name);
    const [inDropZone, setInDropZone] = useState(false);
    const [sliderMarks, setSliderMarks] = useState([]);
    //const [timelineElements, setTimelineElements] = useState(elements || []);
    const [sliderTestValue, setSliderTestValue] = useState(0);
   
    let containerStyle = {
        backgroundColor: '#222',
        width:'100%',
        height:150,
        border:'solid 1px #222',
        borderRadius: 6,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleDragEnter..');
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleDragLeave..');
        setInDropZone(false);
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
        console.log('handleDragOver..');
        setInDropZone(true);
    };
    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleDrop..');
        setInDropZone(false);
        console.log(e.dataTransfer.files);
        e.dataTransfer.clearData();
    };

    const handleSliderChange = (time) => {
        debounce(()=> window.broadcast('EVENT_SEQUENCE_SLIDER_CHANGED', {time}), 10 );
    };
    
    const debounce = (callback, delay) => {
        if(debounceHandle){
            window.clearTimeout(debounceHandle);
        }

        debounceHandle = window.setTimeout(()=> {
            callback();
        }, delay);
    }

    useEffect(() => {
        if(appInitialized){ return; }
        appInitialized = true;

        let marks = [];
        for(let i=0; i<=maxDuration+5; i++){
            if(i % 5 == 0){
                marks.push({label:`00:${i.toString().padStart(2, '0')}`,value:i});
            }
        }
        setSliderMarks(marks);

        let selem = document.querySelector('.timeine-slider .css-eg0mwd-MuiSlider-thumb');
        if(selem){
            let div = document.createElement("div");
            div.style.backgroundColor = '#1976d2';
            div.style.width = '1px';
            div.style.height = '300px';
            div.style.position = 'relative';
            div.style.top = '150px';
            div.style.left = '0px';
            div.style.zIndex = 3000;
            selem.appendChild(div);
        }

        window.listen('EVENT_SCENE_PLAY', newValue => {
            console.log('Play', newValue.play);
            if(!newValue.play){
                playSequence();
            }
            else{
                if(playTimerHandle){
                    clearInterval(playTimerHandle);
                }
            }
        });

        /*
        let rr = 0.0;
        let hnd = setInterval(()=>{
            //alert(rr);
            rr = rr + 0.05;
            setSliderTestValue(rr);
            if(rr > 14){
                clearInterval(hnd);
            }
        }, 20);
        */
        //setSliderTestValue(7);

        //playSequence();

    }, []);

    const playSequence = () => {
        let playTime = 0.00;
        playTimerHandle = setInterval(()=>{
            playTime = playTime + 0.002;
            setSliderTestValue(playTime);
            //handleSliderChange(playTime*100);
            if(playTime > 12){
                clearInterval(playTimerHandle);
            }
        }, 10); 
    }
   
    return (
    <div style={containerStyle} 
        
        className={inDropZone ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone'}
        /*onDrop={e => handleDrop(e)}
        onDragOver={e => handleDragOver(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}*/
        >
        
        <div style={{fontSize:7, userSelect: 'none', color:'#444', width:'100%', borderTopLeftRadius:6, position:'absolute', top:54, left:2, height:24, backgroundColor:'transparent'}}>
            {sliderMarks.map(x=>
                <>
                <div style={{position:'absolute',color:'#fff', top:-2,  width:30, textAlign:'center', left:x.value*10}}>{x.label}</div>
                <div style={{position:'absolute',color:'#fff', top:6, left:x.value*10+14}}>|</div>
                </>
            )
            }
        </div>
        
        <TimelineSlider id="1" value={sliderTestValue} onChange={handleSliderChange} />
        <div>
            { elements.map(x=>
                <TimelineElement key={x.id} id={x.id} name={x.name} stime={x.startTime} etime={x.endTime} pos={x.pos} />
            )}
            
        </div>

    </div>
    )
}