import React, { useState, useEffect } from 'react';
import ResizableContainer from '../ResizableContainer';

let appInitialized = false;
let vid;

export default function Player() {
  const [video, setVideo] = React.useState('');
  const [medias, setMedias] = React.useState([]);
  //const [imgPreviewUrl, setImgPreviewUrl] = React.useState('');
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay, setIsPlay] = React.useState(false);
   //const [sequencePlayerTime] = useGlobalState('sequencePlayerTime'); 

  useEffect(() => {
    if(appInitialized){ return; }
    appInitialized = true;

    vid = document.getElementById("video");

    window.listen('EVENT_MEDIA_FILE_SELECTED', newValue => {
       console.log('Video Received in Player', newValue);
       let videoName = newValue.name;
       setVideo(newValue.url);
       setShowVideo(true);
    });

    window.listen('EVENT_TIMELINE_POSITION_CHANGED', newValue => {
      for(let [key, value] of window.mediaScenes){
        document.getElementById(`img-player-${key}`).parentNode.style.zIndex = 150+(50-value.layer);
      }
    });

    window.listen('EVENT_SEQUENCE_SLIDER_CHANGED', newValue => {
      console.log('Received sequence slider change time', newValue.time);

      let sliderValue  = newValue.time;
      //console.log('sliderValue:', sliderValue);

      for(let [key, value] of window.mediaScenes){
        let sceneObj = window.mediaScenes.get(key);
        let offSet = sceneObj.start;

        //40000/18 => 2222.22
        //2222.22/50 => 44.44

        
        console.log(key, sceneObj.duration , ', OffSet', offSet, ', Slider', sliderValue);
        let imgPlayer = document.getElementById(`img-player-${key}`);
        
        if(sliderValue >= offSet){
          let mediaObj = window.mediaFrames.get(key);
          let mediaScene = window.mediaScenes.get(key);
          let framesAvailablePerSecond = (mediaObj.frameImages.length/mediaScene.duration);
          let frameIndex = parseInt((sliderValue-offSet)/(10/framesAvailablePerSecond));
          console.log('frameIndex', frameIndex, mediaObj.frameImages.length, framesAvailablePerSecond);
          imgPlayer.src = mediaObj.frameImages[frameIndex];
        }
        else{
          imgPlayer.src = '';
        }

      };
      
      //let mediaFrames = window.mediaFrames.get(newValue.name);
      //setImgPreviewUrl(mediaFrames.frameImages[sliderValue]);

      //vid.currentTime = newValue.time/30;
      //vid.duration
      //vid.buffered //TimeRanges { length: 0 }
      //video.audioTracks[i]
      //if (vid.networkState === 2) {
        // Still loading…
      //}

      //const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      //vid.srcObject = mediaStream;
      //https://html.spec.whatwg.org/multipage/media.html

    });

    window.listen('EVENT_MEDIA_FIRST_FRAME_OBTAINED', newValue => {
      console.log('Frames 1' + ' ' + JSON.stringify(newValue));
      
      let temp = [];
      let id=1;
      for(let [key, value] of window.mediaScenes){
        let newObj = {id: key, layer: value.layer, name: value.name};
        temp.push(newObj);
      };
      //alert(JSON.stringify(temp));
      setMedias(temp);

      setTimeout(()=>{
        console.log(newValue.id, '=> ' ,newValue.frameImage);
        let imgPlayer = document.getElementById(`img-player-${newValue.id}`);
        console.log(imgPlayer.src);
        imgPlayer.src = newValue.frameImage;
      }, 0);
      
      //setImgPreviewUrl(newValue.frameImage);
    });
    
  }, []);

  const onClickPlay = (e) => {
    e.preventDefault();

    window.broadcast('EVENT_SCENE_PLAY', {play: isPlay});

    if(!isPlay){
      for(let [key, val] of window.mediaScenes) {
        console.log('PLAYING ...', key, val.start/10)
        decodeFramesWithPlayingSpeedOnly(key, val.layer, val.start/10);
      }
    }
    else{
      for(let [key, val] of window.mediaScenes) {
        console.log('STOPPING ...', key)
        let videoElement = document.getElementById(`video-element-${key}`);
        const imgPlayer = document.getElementById(`img-player-${key}`);
        let canvasElem = document.getElementById(`canvas-player-${key}`);
        videoElement.pause();
        imgPlayer.parentNode.style.display = '';
        canvasElem.style.display = 'none';
      }
      
    }

    setIsPlay(!isPlay);
  }

  //**********************************************************************//
  const decodeFramesWithPlayingSpeedOnly = async (id, layer, startTime=0.00) => {
    console.log(id, '==>' , layer);
    let stopped = false;
    const track = await getVideoTrack();
    const processor = new window.MediaStreamTrackProcessor(track);
    const reader = processor.readable.getReader();
    readChunk();

    const imgPlayer = document.getElementById(`img-player-${id}`);
    const playerBounds = imgPlayer.getBoundingClientRect();
    imgPlayer.parentNode.style.display = 'none';
    
    const showCanvas = true;
    let canvasElem = document.getElementById(`canvas-player-${id}`);
    if(!canvasElem){
       console.log(`canvas-player-${id} created..`)
       canvasElem = document.createElement('canvas');
       canvasElem.id = `canvas-player-${id}`;
       canvasElem.style.position = 'absolute';
       document.body.appendChild(canvasElem);
    }
    console.log(`canvas-player-${id} accessed..`)
    
    canvasElem.width = 640;
    canvasElem.height = 360;
    canvasElem.style.zIndex = 1000+(50-layer); //Thinking maximum 50 videos allowed
    canvasElem.style.width = playerBounds.width +'px';
    canvasElem.style.height = playerBounds.height +'px';
    canvasElem.style.top = playerBounds.top +'px';
    canvasElem.style.left = playerBounds.left + 'px';
    canvasElem.style.display = '';

    let context = canvasElem.getContext("2d"); 

    function readChunk() {
      stopped = window.get('EVENT_SCENE_PLAY').play;
      reader.read().then(async({ done, value }) => {
        if (value) {
          //console.log('CHUNK =>> Playing:', window.get('EVENT_SCENE_PLAY').play, ', ' ,value);
          context.drawImage(value, 0, 0, canvasElem.width, canvasElem.height);
          /*
          //console.log(canvasElem.toDataURL("image/png"));
          canvasElem.toBlob(
            (blob) => {
              let videoUrl = URL.createObjectURL(blob);
              console.log('PLAYING .. => ', videoUrl);
              imgPlayer.src = videoUrl;
            },
            "image/jpeg",
            JPEG_COMPRESSION
          );
          */
          value.close();
        }
        if (!done && !stopped) {
          readChunk();
        } else {
          console.log('READY  !!')
        }
      });
    }

    async function getVideoTrack() {
      let video = document.getElementById(`video-element-${id}`);
      if(!video){
        console.log(`video-element-${id} created..`)
        video = document.createElement('video');
        video.id = `video-element-${id}`;
        document.body.appendChild(video);
     }
     console.log(`video-element-${id} accessed..`)
      //var video = videoElement ? videoElement : document.createElement('video');
      video.preload = 'auto';
      //video.playbackRate = 1.25;
      video.style.display = 'none';
      //video.crossOrigin = "anonymous";
      //video.controls = true;
      video.controls = "muted";
      console.log('PLAYING URL ...', window.medias.get(id).url);
      video.src = window.medias.get(id).url;

      if(false){
        video.style.display = '';
        video.style.position = 'absolute';
        video.style.top = '320px';
        video.style.width = '300px';
        video.style.height = '210px';
      }

      //console.log(video.currentTime, startTime);
      video.currentTime = startTime;
      //console.log(video.currentTime, startTime, video.src);
      await video.play();
      const [track] = video.captureStream().getVideoTracks();
      video.onended = (evt) => {
        imgPlayer.parentNode.style.display = '';
        canvasElem.style.display = 'none';
        track.stop();
      }
      return track;
    }
  }; //end of decodeFramesWithPlayingSpeedOnly()
  //**********************************************************************//


  return (
    <div className="ve-tab">
        <div className="ve-screen">
          <div className="ve-top-mask"></div>
          <div className="ve-left-mask"></div>
          <div className="ve-right-mask"></div>
          <div className="ve-bottom-mask">
            <span onClick={onClickPlay} style={{userSelect:'none'}} class="material-symbols-rounded">{isPlay?'stop_circle':'play_circle'}</span>
          </div>
          
          {/*<img hidden style={{height:300}} src={''} />*/}
          {medias.map(x=>
          <ResizableContainer id={x.id} layer={x.layer}>
            {/*<video hidden id="video" style={{width:'100%'}} src={video} controls={false} loop={false}  autoPlay={false} muted={false} /> */}
            <img id={`img-player-${x.id}`} style={{width:'100%'}} />
          </ResizableContainer>
          )}

         
      </div>
    </div>
  );
}