import React, { useState, useEffect }  from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { SortableContainer, SortableElement, arrayMove, SortableHandle } from "react-sortable-hoc";
import {Resizable} from "re-resizable";
import imgApproval from "../../assets/images/approval.png";

import TimelineGrid from "../TimelineGrid";


let initialized = false;

/*
const Handle = SortableHandle(() => (
    <div style={{ position: "absolute", right: 0, width:'100%', backgroundColor:'green' }}>Drag me</div>
));

const SortableItem = SortableElement(({ value }) => (
    <Resizable
        style={{ border: "1px solid black", backgroundColor:'red' }}
        defaultSize={{ width: "30%", height: "30px" }}
    >
        <>
        <Handle />
        {value}
        </>
    </Resizable>
));

const SortableList = SortableContainer(({ items }) => {
return (
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
    {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
    </div>
);
});
*/

export default function Timeline() {
    const [items, setItems] = useState(["Item-1", "Item-2"]);
    //const [imgFrame, setImgFrame] = useState(imgApproval);
    const [videoUrl, setVideoUrl] = useState('');
    const [timelineElements, setTimelineElements] = useState([]);
    const [maxDuration, setMaxDuration] = useState(60);

    //const [imgFrame1, setImgFrame1] = useState(imgApproval);
    //const [imgFrame2, setImgFrame2] = useState(imgApproval);
    //const [imgFrame3, setImgFrame3] = useState(imgApproval);
    //const [imgFrame4, setImgFrame4] = useState(imgApproval);
    //const [imgFrame5, setImgFrame5] = useState(imgApproval);
    
    const getFrame = async (name, url) => {
        const outputFileName = `${name}.png`;
        /*
        await window.ffmpeg.run(...[
            '-i',
            name,
            '-ss',
            '00:00:12.435',
            '-frames:v',
            '1',
            outputFileName,
        ]);
        */

        /*
        //find the most representative frames in the video
        await window.ffmpeg.run(...[
            '-i',
            name,
            '-vf',
            'thumbnail,scale=640:360',
            '-frames:v',
            '1',
            outputFileName,
        ]);
        */

       /* 
       //find the most representative frames in the video
       await window.ffmpeg.run(...[
            '-i',
            name,
            '-vf',
            'thumbnail=n=100',
            '-frames:v',
            '100',
            'thumb%04d.png',
        ]);
        */

        //thumps every 8th second
        await window.ffmpeg.run(...[
            '-i',
            name,
            '-vf', 
            'fps=1/8', 
            'thumb_%04d.png'
        ]);

        let buffer1 = await window.ffmpeg.FS('readFile', 'thumb_0001.png');
        console.log('-------- FFMEPG WRITTEN !! -----------');
        console.log(buffer1);

        /*
        let buffer1 = await window.ffmpeg.FS('readFile', 'thumb_0001.png');
        let blob1 = new Blob([buffer1]);
        var url1 = URL.createObjectURL(blob1);
        console.log(url1);
        setImgFrame1(url1);

        let buffer2 = await window.ffmpeg.FS('readFile', 'thumb_0002.png');
        let blob2 = new Blob([buffer2]);
        var url2 = URL.createObjectURL(blob2);
        console.log(url2);
        setImgFrame2(url2);

        let buffer3 = await window.ffmpeg.FS('readFile', 'thumb_0003.png');
        let blob3 = new Blob([buffer3]);
        var url3 = URL.createObjectURL(blob3);
        console.log(url3);
        setImgFrame3(url3);

        console.log('ffmpeg.run ..');
        let buffer = await window.ffmpeg.FS('readFile', 'thumb_0004.png');
        console.log('ffmpeg.readFile ..', outputFileName);
        let blob = new Blob([buffer]);
        var url = URL.createObjectURL(blob);
        console.log(url);
        setImgFrame(url);
        */

    }

    const getVideo = async () => {
        return await (await fetch('https://bobbyt.s3.amazonaws.com/sample-small.mp4')).arrayBuffer();
    }

    const test1 = async () => {
        let buffer = await getVideo();
        console.log(buffer);
        let blob = new Blob([buffer]);
        console.log(blob);
        var url = URL.createObjectURL(blob);
        console.log(url);
        setVideoUrl(url);
        //URL.revokeObjectURL(url);
    }

    useEffect( () => {
        if(initialized) { return; }
        initialized = true;

         window.listen('EVENT_MEDIA_FILE_SELECTED', newValue => {
            console.log('Video Received in Timeline', newValue);
            let videoName = newValue.name;

            //alert(JSON.stringify(Array.from(window.medias)));

            var temp = [];
            let maxDur = 60;
            let i = 1;
            for(let [key, value] of window.mediaScenes){
                let endTime = value.end === -1 ? 60 : value.end;
                let mediaObj = {id: key, name: value.name, startTime: value.start, endTime, pos: value.layer};
                temp.push(mediaObj);
                maxDur = Math.max(maxDur, endTime);
            }
            setMaxDuration(maxDur);
            setTimelineElements(temp);
            
         });

        window.listen('EVENT_MEDIA_DURATION_RECIEVED', newValue => {
            console.log('Media Duration', newValue.duration);
            
            var temp = [];
            let maxDur = 60;
            for(let [key, value] of window.mediaScenes){
                let endTime = newValue.id == key ? parseInt(newValue.duration) : value.end;
                let mediaObj = {id: key, name: value.name, startTime: value.start, endTime, pos: value.layer};
                temp.push(mediaObj);
                maxDur = Math.max(maxDur, endTime);
            }
            setMaxDuration(maxDur);
            setTimelineElements(temp);
            
            //setImgPreviewUrl1(newValue.frameImage);
            //let imgFrame1 = document.getElementById(`img-frame1-${newValue.name}`);
            //imgFrame1.src = newValue.frameImage;
        });

         window.listen('EVENT_FFMPEG_MEDIA_WRITTEN', newValue => {
            //alert('EVENT_FFMPEG_MEDIA_WRITTEN')
            getFrame(newValue.name, newValue.url);
         });

         

    });

    /*
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };
    */

  return (
    <div>
        <TimelineGrid key="1" maxDuration={maxDuration} elements={timelineElements} />
    </div>
  );
}