import Store from 'rubys';
import { createFFmpeg } from '@ffmpeg/ffmpeg';  //Needs to add Cross-Origin-Opener-Policy and Cross-Origin-Embedder-Policy

const initializeApplication = (eventName, object) => {
  console.log('APP INITIALIZING ..');

  window.broadcast = (eventName, object) => {
    console.log('Broadcasting ' + eventName );
    Store.set(eventName, object);
  }

  window.listen = (eventName, callBack) => {
    console.log('Recieved ' + eventName );
    Store.bind(eventName, callBack);
  }

  window.get = (eventName) => {
    console.log('Getting value ' + eventName );
    return Store.get(eventName);
  }

  window.medias = new Map();
  window.mediaFrames = new Map();
  window.mediaScenes = new Map();

  window.dpcm = calcScreenDPI();
  console.log(`1cm => ${window.dpcm}px`);

  window.isMobile = window.matchMedia ? window.matchMedia("(max-width: 600px)").matches: false;
  
  //initializeWasmFFMPEG(); //DO NOT DELETE THIS LINE
  console.log('WARNING !! initializeWasmFFMPEG() temperoraly disabled');

};

function calcScreenDPI() {
  const el = document.createElement('div');
  el.style = 'width: 1cm;'
  document.body.appendChild(el);
  const dpi = el.getBoundingClientRect().width;
  document.body.removeChild(el);

  return dpi.toFixed(2);
}

//DO NOT DELETE THIS METHOD
const initializeWasmFFMPEG = async () => {
  const ffmpeg = createFFmpeg({ 
      log: true,
      corePath: "ffmpeg/ffmpeg-core.js", //loading from current local website (comment this to load from internet)
  });
  console.log('ffmpeg initialized.');
  if (ffmpeg.isLoaded() === false) {
      await ffmpeg.load();
      console.log('ffmpeg loaded.');
  }
  window.ffmpeg = ffmpeg;
}

export default initializeApplication;
