import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TheatersIcon from '@mui/icons-material/Theaters';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ImageIcon from '@mui/icons-material/Image';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          <Box component={DeleteIcon} color="gray" sx={{ ml: 2 }} />
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

let appInitialized = false;

export default function GmailTreeView() {
  const [medias, setMedias] = React.useState([]);

  useEffect(() => {
    if(appInitialized){ return; }
    appInitialized = true;

    window.listen('EVENT_MEDIA_FILE_SELECTED', newValue => {
      console.log('Video Received in Bins', newValue);
      
      let med = [];
      for(let [key, value] of window.medias){
        med.push({id: key, name:value.name, type:value.type, size: value.size});
      };
      setMedias(med);

   });

  });

  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: '100%', marginTop:2, zoom:0.8, flexGrow: 1, width: '90%' }}
    >
      {medias.map(x=>
      <StyledTreeItem nodeId={x.id} labelText={x.name} labelInfo={`${((x.size/1000)/1000).toFixed(1)} MB`} labelIcon={VideoLibraryIcon} />
      )}

      {/*
      <StyledTreeItem nodeId="1" labelText="Sequence 1" labelIcon={VideoLibraryIcon} />
      <StyledTreeItem nodeId="3" labelText="Sequence 2" labelIcon={VideoLibraryIcon}>
        <StyledTreeItem
          nodeId="5"
          labelText="beach-volley-ball.avi"
          labelIcon={OndemandVideoIcon}
          labelInfo="90 mb"
          color="#1a73e8"
          bgColor="#e8f0fe"
        />
        <StyledTreeItem
          nodeId="6"
          labelText="smile.jpg"
          labelIcon={ImageIcon}
          labelInfo="2,294 mb"
          color="#e3742f"
          bgColor="#fcefe3"
        />
      </StyledTreeItem>
      <StyledTreeItem nodeId="4" labelText="Sequence 3" labelIcon={VideoLibraryIcon} />
  */}
    </TreeView>
  );
}