import React, { useState, useEffect }  from 'react';
import './resizable-container.css';

let activeId = '0';
let parentMouseDown = false;
let bottomRightMouseDown = false;

let bottomRightX = -1;
let bottomRightY = -1;

let parentX = -1;
let parentY = -1;
let parentLeft = -1;
let parentTop = -1;

let mouseDown = false;

export default function ResizableContainer(props) {
    let {id, value, layer, children} = props;
    let [inDropZone, setInDropZone] = useState(false);
    let [sliderValue, setSiderValue] = useState(value);

    const mousemoveEvent = (e) => {
        if(mouseDown){
            if(parentMouseDown){
                let currentTarget = document.getElementById(`parent-resize-${activeId}`);
                console.log(currentTarget.style.left, e.pageX, parentX, parentLeft);
                let calcLeft = e.pageX-(300+parentLeft);
                let calcTop = e.pageY-(parentTop);
                currentTarget.style.left = calcLeft + 'px';
                currentTarget.style.top = calcTop + 'px';
            }
            else if(bottomRightMouseDown){
                let currentTarget = document.getElementById(`parent-resize-${activeId}`);
                let calcWidth = e.pageX-300-bottomRightX;
                currentTarget.style.width = calcWidth + 'px';
            }
        }
    }

    useEffect(() => {
        let parentTarget = document.getElementById(`parent-resize-${id}`);
        let bottomRight = document.getElementById(`resize-br-${id}`);
        
        if(typeof value != 'undefined'){
            parentTarget.style.left = ((value*50)+11) + 'px';
        }

        parentTarget.addEventListener("mousedown", function(e) {
            console.log('parent mousedown ..');
            if(e.target.className == 'resize-overlay'){
                //console.log(e.target);
                activeId = id;
                parentMouseDown = true;
                parentX = e.pageX;
                parentY = e.pageY;
                parentLeft = e.layerX;//e.target.offsetLeft;
                parentTop = e.layerY;//e.target.offsetTop;
                //console.log('==>', parentX, parentY, parentLeft, parentTop);
            }
        }); 

        bottomRight.addEventListener("mousedown", function(e) {
            console.log('bottomRight mousedown ..');
            bottomRightMouseDown = true;
            activeId = id;
            bottomRightX = e.target.parentNode.offsetLeft;//e.pageX-e.target.parentNode.offsetLeft;
            bottomRightY = e.pageY-e.target.parentNode.offsetTop;
        }); 

        document.addEventListener('mousedown', (e) => {
            console.log('document mousedown ...');
            mouseDown = true;
            document.addEventListener('mousemove', mousemoveEvent);
        });

        document.addEventListener('mouseup', (e) => {
            console.log('document mouseup ...');
            mouseDown = false;
            parentMouseDown = false;
            bottomRightMouseDown = false;
            document.removeEventListener('mousemove', mousemoveEvent);
        });

    }, []);

    return (
    <div id={`parent-resize-${id}`} style={{position:'absolute', zIndex:150+(50-layer), width:640, left:130, top:40, userSelect: 'none'}} className="resize-container">
        <div style={{width:'100%', backgroundColor:'transparent', height:'100%', zIndex:300, position:'absolute'}} className="resize-overlay" />
        <div style={{left:-5, top:-7, position:'absolute'}} className="resize-tl" />
        <div style={{right:-5, top:-7, position:'absolute'}} className="resize-tr" />
        {children}
        <div style={{left:-5, bottom:-5, position:'absolute'}} className="resize-bl" />
        <div id={`resize-br-${id}`} style={{right:-5, bottom:-5, position:'absolute'}} className="resize-br" />
    </div>
    )
}