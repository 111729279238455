import React, { useState, useEffect }  from 'react';
import './timeline-slider.css';

let activeId = '0';
let parentMouseDown = false;

let parentX = -1;
let parentLeft = -1;

let mouseDown = false;
let SLIDER_OFFSET = 11;


export default function TimelineSlider({id, value, onChange}) {
    let [inDropZone, setInDropZone] = useState(false);
    let [sliderValue, setSiderValue] = useState(value);

    const mousemoveEvent = (e) => {
        if(mouseDown){
            if(parentMouseDown){
                let currentTarget = document.getElementById(`slider-element-${activeId}`);
                let y = e.pageY;

                let calcLeft = e.pageX-(parentX-parentLeft);
                if(calcLeft > 10){
                    currentTarget.style.left = calcLeft + 'px';
                    if(onChange){
                        onChange(calcLeft-SLIDER_OFFSET);
                    }
                }
                else{
                    currentTarget.style.left = '11px';
                }
            }
        }
    }

    useEffect(() => {
        let parentTarget = document.getElementById(`slider-element-${id}`);
        
        if(typeof value != 'undefined'){
            parentTarget.style.left = ((value*50)+11) + 'px';
        }

        parentTarget.addEventListener("mousedown", function(e) {
            if(this === e.target){
                activeId = id;
                parentMouseDown = true;
                parentX = e.pageX;
                parentLeft = e.target.offsetLeft;
            }
            else{
                console.log('Bubbled ..');
            }
        }); 

        document.addEventListener('mousedown', (e) => {
            mouseDown = true;
            document.addEventListener('mousemove', mousemoveEvent);
        });

        document.addEventListener('mouseup', (e) => {
            mouseDown = false;
            parentMouseDown = false;
            document.removeEventListener('mousemove', mousemoveEvent);
        });

    }, []);

    return (
    <div id={`slider-element-${id}`} style={{left: (typeof value != 'undefined') ? (((value*50)+11) + 'px') : 12, borderTopLeftRadius:'40%',borderTopRightRadius:'40%', width:11, top:36, position:'absolute'}} className="slider-container">
        <div style={{left:5, width:1, top:14, position:'relative'}} className="slider-tail" />
    </div>
    )
}