import React, { useState, useEffect }  from 'react';
import './timeline-grid.css';

let activeId = '0';
let parentMouseDown = false;
let leftMouseDown = false
let rightMouseDown = false;

let parentX = -1;
let parentLeft = -1;

let leftX = -1
let leftWidth = -1
let rightX = -1;

let mouseDown = false;
let appInitialized = false;

export default function TimelineElement({id, name, stime, etime, pos}) {

    const HEIGHT = 50;
    const OFFSET = 54;
    const OFFSET_POINT = 74;
    const OFFSET_START = 590;

    const mousemoveEvent = (e) => {
        if(mouseDown){
            if(parentMouseDown){
                let currentTarget = document.getElementById(`parent-element-${activeId}`);
                let y = e.pageY;    
                
                let start = 528; //absolute y postion of mouse
                let offset = 74; //absolute starting position of first scene
                let maxSceneRows = 4;
                //let height = 50; //height of the scene
                //let offsetHeight = 54; //height of the scene + 4px gap

                for(let i = 0; i < maxSceneRows-1 ; i++){
                    //console.log(start, start + 50);
                    if( y > (start + 30) && y < (start + 50) && currentTarget.offsetTop === offset + (54*i)){
                        currentTarget.style.top = (currentTarget.offsetTop + 54) + 'px';
                    }
                    else if( y > (start + 0) && y < (start + 20) && currentTarget.offsetTop === offset + (54*(i+1))){
                        currentTarget.style.top = (currentTarget.offsetTop - 54) + 'px';
                    }

                    start = start + 50 + 4;
                }

                let calcLeft = e.pageX-(parentX-parentLeft);
                if(calcLeft >= 16){
                    currentTarget.style.left = calcLeft + 'px';
                }
                else{
                    currentTarget.style.left = '16px';
                }
            }
            else if(leftMouseDown){
                let currentTarget = document.getElementById(`parent-element-${activeId}`);
                let calcLeft = e.pageX-leftX;
                if(calcLeft > 40){
                    currentTarget.style.left = calcLeft + 'px';
                    currentTarget.style.width = (leftWidth-e.pageX) + 'px';
                }
                
            }
            else if(rightMouseDown){
                let currentTarget = document.getElementById(`parent-element-${activeId}`);
                let calcLeft = e.pageX-rightX;
                currentTarget.style.width = calcLeft + 'px';
            }
        }
    }

    useEffect(() => {
       
        let leftTarget = document.getElementById(`left-dragbar-${id}`);
        let rightTarget = document.getElementById(`right-dragbar-${id}`);
        let parentTarget = document.getElementById(`parent-element-${id}`);
        
        parentTarget.addEventListener("mousedown", function(e) {
            if(this === e.target){
                activeId = id;
                parentMouseDown = true;
                parentX = e.pageX;
                parentLeft = e.target.offsetLeft;
            }
            else{
                if(e.target.id.startsWith('img-frame')){
                    activeId = id;
                    parentMouseDown = true;
                    parentX = e.pageX;
                    parentLeft = e.target.parentNode.offsetLeft;
                }
                else{
                    console.log('Bubbled ..', e.target);
                }
            }
        }); 

        leftTarget.addEventListener("mousedown", function(e) {
            activeId = id;
            leftX = e.pageX-e.target.parentNode.offsetLeft;
            leftWidth = e.pageX+e.target.parentNode.offsetWidth-2;
            leftMouseDown = true;
        });  

        rightTarget.addEventListener("mousedown", function(e) {
            activeId = id;
            rightX = e.pageX-e.target.parentNode.offsetWidth;
            rightMouseDown = true;
        });

        document.addEventListener('mousedown', (e) => {
            mouseDown = true;
            document.addEventListener('mousemove', mousemoveEvent);
        });

        document.addEventListener('mouseup', (e) => {
            mouseDown = false;
            leftMouseDown = false;
            rightMouseDown = false;
            parentMouseDown = false;
            document.removeEventListener('mousemove', mousemoveEvent);

            //updating the start position, end position if the media in the timeline
            let mediaSceneObject = window.mediaScenes.get(activeId);
            let currentTarget = document.getElementById(`parent-element-${activeId}`);
            //console.log(activeId, ',currentTarget.offsetLeft:', currentTarget.offsetLeft);
            //console.log('OFFSET TOP ....', currentTarget.offsetTop);
            
            mediaSceneObject.layer = (currentTarget.offsetTop-20)/54; //How we got 20 ? =? 74-20 => 54
            mediaSceneObject.start = currentTarget.offsetLeft - 11;
            console.log('SET PLAYER START POSITION TO', mediaSceneObject.start);
            //console.log('SETTING mediaScenes', activeId , currentTarget.offsetLeft);
            window.mediaScenes.set(activeId, mediaSceneObject);
            window.broadcast('EVENT_TIMELINE_POSITION_CHANGED', new Date().valueOf()); //Sending date because there is a bug which wont trigger if the value is same.
        });


        if(!appInitialized){

            window.listen('EVENT_MEDIA_10_FRAMES_OBTAINED', newValue => {
                renderTimelineThumbnails(newValue.id);
            });

            window.listen('EVENT_MEDIA_20_FRAMES_OBTAINED', newValue => {
                renderTimelineThumbnails(newValue.id);
            });

            window.listen('EVENT_MEDIA_ALL_FRAMES_OBTAINED', newValue => {
                renderTimelineThumbnails(newValue.id);

                /*
                console.log('Frames', newValue);
                let mediaFrames = window.mediaFrames.get(newValue.id).frameImages;
                console.log('ALL FRAMES RECEIVED', mediaFrames.length);
                */

                /*
                let url1 = mediaFrames[0];
                */
                //let url2 = mediaFrames[parseInt(mediaFrames.length*25/100)];
                //let url3 = mediaFrames[parseInt(mediaFrames.length*50/100)];
                /*
                let url4 = mediaFrames[parseInt(mediaFrames.length*75/100)];
                let cssUrls = `url(${url1}), url(${url4})`
                console.log(cssUrls);
                let target = document.getElementById(`parent-element-${newValue.id}`);
                target.style.backgroundImage = cssUrls;
                target.style.backgroundSize = '90px';
                target.style.backgroundPosition = 'left, right';
                target.style.backgroundRepeat = 'no-repeat, repeat';
                */
                //setImgPreviewUrl1(mediaFrames[parseInt(mediaFrames.length*/2)]);
                /*
                let imgFrame2 = document.getElementById(`img-frame2-${newValue.name}`);
                let imgFrame3 = document.getElementById(`img-frame3-${newValue.name}`);
                let imgFrame4 = document.getElementById(`img-frame4-${newValue.name}`);
                imgFrame2.src = mediaFrames[parseInt(mediaFrames.length*25/100)];
                imgFrame3.src = mediaFrames[parseInt(mediaFrames.length*50/100)];
                imgFrame4.src = mediaFrames[parseInt(mediaFrames.length*75/100)];
                */
            });

            window.listen('EVENT_MEDIA_FIRST_FRAME_OBTAINED', newValue => {
                console.log('Frames', newValue);
                
                let target = document.getElementById(`parent-element-${newValue.id}`);
                target.style.backgroundImage = `url(${newValue.frameImage})`;
                target.style.backgroundSize = '90px';
                //setImgPreviewUrl1(newValue.frameImage);
                //let imgFrame1 = document.getElementById(`img-frame1-${newValue.name}`);
                //imgFrame1.src = newValue.frameImage;
            });
        }

        appInitialized = true;
        
    }, []);

    const renderTimelineThumbnails = (mediaId) => {
        let mediaFrames = window.mediaFrames.get(mediaId).frameImages;
        console.log('10 FRAMES RECEIVED', mediaFrames.length);
        let url1 = mediaFrames[0];
        let url4 = mediaFrames[parseInt(mediaFrames.length*75/100)];
        let cssUrls = `url(${url1}), url(${url4})`
        let target = document.getElementById(`parent-element-${mediaId}`);
        target.style.backgroundImage = cssUrls;
        target.style.backgroundSize = '90px';
        target.style.backgroundPosition = 'left, right';
        target.style.backgroundRepeat = 'no-repeat, repeat';
    }

    return (
    <div id={`parent-element-${id}`} style={{left:(stime+16), width:((etime-stime)*10), top:((pos*54)+20), position:'absolute'}} className="dragbar-container">
        <div id={`left-dragbar-${id}`} className="dragbar" style={{position:'absolute', left:'0px', borderTopLeftRadius:5, borderBottomLeftRadius:5}}></div>
        <div id={`right-dragbar-${id}`} className="dragbar" style={{position:'absolute', right:'0px', borderTopRightRadius:5, borderBottomRightRadius:5}}></div>
        {/*
        <img id={`img-frame1-${name}`} style={{height:50}} />
        <img id={`img-frame2-${name}`} style={{height:50}} />
        <img id={`img-frame3-${name}`} style={{height:50}} />
        <img id={`img-frame4-${name}`} style={{height:50}} />
        */}
    </div>
    )
}